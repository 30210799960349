<template lang="pug">
  .ota(
    :class="{ 'ota-inactive': isOtaInactive }"
  )
    p {{ otaName }}
    AppTooltip.warning-icon(
      v-if="isDisabled"
      :title="tooltipMessage"
      icon="exclamation-triangle"
    )
</template>

<script>
  export default {
    props: {
      otaData: {
        type: Object,
        default: () => new Object()
      },
      otaName: String
    },

    components: {
      AppTooltip: () => import("@/components/elements/AppTooltip")
    },

    computed: {
      isDisabled() {
        return (
          this.isOtaInactive ||
          this.isCarClassNotMatched ||
          this.isShopNotMatched ||
          this.isSourceShopInactive ||
          this.isSourceCarClassInactive
        )
      },

      isOtaInactive() {
        return this.otaData.inactive_ota
      },

      isCarClassNotMatched() {
        return this.otaData.not_matched_car_class
      },

      isShopNotMatched() {
        return this.otaData.not_matched_shop
      },

      isSourceShopInactive() {
        return this.otaData.inactive_source_shop
      },

      isSourceCarClassInactive() {
        return this.otaData.inactive_source_car_class
      },

      tooltipMessage() {
        if (this.isOtaInactive) {
          return this.$t("inventory_management.ota_account_disabled_tooltip")
        }

        return this.$t("inventory_management.improper_matching_tooltip")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .ota
    align-items: center
    display: flex
    height: 30px
    border: 0
    color: $link-color
    font-size: 0.8rem
    font-weight: 400
    padding-left: 5px
    text-transform: uppercase
    border-bottom: 1px solid $border-element-color

    &.ota-inactive
      background-color: $default-gray-light
      color: $default-gray

    ::v-deep
      .warning-icon
        svg
          &.default
            color: $default-red

    p
      height: 29px // 29px = 30px wrapper height - 1px wrapper border
      line-height: 29px
      margin: 0
      vertical-align: middle
</style>
